.maincontainer {
  padding: 72px 10px 0 10px;
  height: 100%;
  margin-left: 0px;
  transition: all 0.2s !important;
  display: flex;
  flex-direction: column;
}

.maincontainer.opened {
  margin-left: 210px;
}

li.content {
  padding: 0 1em !important;
}

.main-dimmer {
  z-index: 997 !important;
}

.fullheight,
.ui.segment.fullheight {
  position: relative !important;
  min-height: 100% !important;
  flex: 1;
}

.ag-product-list {
  height: calc(100% - 36px);
}

.ag-grid-header {
  height: 38px;
}

.ag-grid-container {
  height: 100%;
}

.ag-grid-container-2 {
  height: calc(100% - 43px);
}

.ag-theme-material .ag-icon-checkbox-checked {
  background-image: url('/images/check.svg') !important;
}

.main-dimmable {
  height: 100%;
}

.plus-button {
  display: flex;
  justify-content: center;
  padding-top: 17px;
  align-items: center;
}

/* START React-Virtualized Table Styles */
.ReactVirtualized__Table {
  margin-top: 20px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ReactVirtualized__Table *,
.ReactVirtualized__Grid * {
  outline: none;
  cursor: pointer;
}

.ReactVirtualized__Table__row.cancelled > .ReactVirtualized__Table__rowColumn {
  opacity: 0.2;
}

.ReactVirtualized__Table__Grid {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

@media (print) {
  .table-split {
    page-break-before: always !important;
  }
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}
.oddRow {
  background-color: #fafafa;
}

/* END React-Virtualized Table Styles */

.hiddenSubmit > button {
  display: none !important;
}

.detail-wrapper {
  padding-bottom: 100px !important;
}

.order-total {
  text-align: right;
  margin: 0 10px;
}

.order-list-header {
  display: flex;
  align-items: center;
}

.order-list-header__search {
  flex-grow: 1;
}

.right-aligned-button {
  text-align: right;
  padding-top: 20px;
}

.greenText {
  color: #1ebc30;
}

.redText {
  color: #db2828;
}

.yellowText {
  color: #b58105;
}

.ag-grid-options {
  position: absolute;
  top: 0;
  right: -45px;
}

.expense-total {
  position: absolute;
  top: 15px;
  right: 15px;
}

.shipping-error__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.form-invoice-list {
  margin-top: 10px;
}

.ship-error {
  background-color: #eee;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding-bottom: 0 !important;
  }
}

.grow > div {
  margin-right: 10px;
}

.dropzone {
  width: 100%;
  min-height: 80px;
  border-radius: 5px;
  border: 5px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  margin: 0;
  word-break: break-all;
}

.dropzone p {
  margin: 0 !important;
}

.dropzone-active {
  background: rgba(0, 255, 0, 0.1);
}

.dropzone-reject {
  background: rgba(255, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .maincontainer {
    padding: 72px 0 0 0;
  }
  .ui.container {
    margin: 0 5px !important;
  }
  .drawer a {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}

@media print {
  .drawer,
  .bbi-header,
  .bottom-menu {
    display: none !important;
  }
  .maincontainer {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}

.resized-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.checkboxes {
  display: flex;
  justify-content: space-around;
}

.checkboxes > .field {
  padding: 10px;
}

.field {
  margin-bottom: 5px !important;
}

.submit-button-container {
  margin-top: 10px;
  text-align: right;
}

.field-error-text {
  color: #9f3a38;
}

.array-buttons {
  display: flex;
  align-items: flex-start;
  padding-top: 22px;
  padding-left: 0px;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .array-buttons {
    display: flex;
    align-items: flex-start;
    padding-top: 0px;
    padding-left: 5px;
  }

  .accordion-buttons {
    display: flex;
    flex-direction: column;
  }

  .accordion-buttons > button {
    margin: 5px 0 !important;
  }

  .accordion-title {
    flex-direction: column;
  }
}

/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}
