.drawer {
  z-index: 998 !important;
  padding-top: 62px;
  transition: all 0.2s !important;
  transform: translateX(-210px) !important;
  overflow-y: scroll;
}

.drawer > div {
  cursor: pointer;
}

.drawer::-webkit-scrollbar {
  width: 0px;
}

.drawer.opened {
  transform: translateX(0px) !important;
}

.sidebarLabel {
  background-color: #995057 !important;
}

.submenu {
  border-left: 5px solid #995057;
}

.watermark {
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: rgba(0, 0, 0, 0.05);
  pointer-events: none;
}
